var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ApiManagement"}},[_c('el-form',{attrs:{"size":"mini","inline":true}},[_c('el-form-item',[_c('el-button-group',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":function () {
              _vm.state = 'add';
              _vm.addShow = true;
            }}}),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-delete"},on:{"click":function($event){_vm.remove(_vm.$constant.method.getArrayValueByKey(_vm.selectionTableData))}}}),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-refresh-right"},on:{"click":function($event){return _vm.$router.go(0)}}})],1)],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入名称","size":"mini"},model:{value:(_vm.where.name),callback:function ($$v) {_vm.$set(_vm.where, "name", $$v)},expression:"where.name"}})],1),_c('el-form-item',[_c('el-select',{attrs:{"size":"mini"},model:{value:(_vm.where.type),callback:function ($$v) {_vm.$set(_vm.where, "type", $$v)},expression:"where.type"}},[_c('el-option',{attrs:{"label":"请选择类型","value":null}}),_c('el-option',{attrs:{"label":"获取","value":1}}),_c('el-option',{attrs:{"label":"上传","value":2}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"mini","type":"primary","plain":"","icon":"el-icon-search"},on:{"click":_vm.getList}},[_vm._v("搜索")]),_c('el-button',{attrs:{"size":"mini","type":"success","plain":"","icon":"el-icon-document"},on:{"click":function($event){_vm.documentShow = true}}},[_vm._v("API文档")])],1)],1),_c('el-table',{attrs:{"size":"mini","border":"","data":_vm.tableData},on:{"selection-change":function (selection) {
        _vm.selectionTableData = selection;
      }}},[_c('el-table-column',{attrs:{"type":"selection"}}),_c('el-table-column',{attrs:{"label":"ID","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"API名称","prop":"name"}}),_c('el-table-column',{attrs:{"label":"API类型"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type)?_c('span',[_vm._v("上传")]):_c('span',[_vm._v("取号")])]}}])}),_c('el-table-column',{attrs:{"label":"API令牌","prop":"token","width":"260"}}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"add_time","width":"140"}}),_c('el-table-column',{attrs:{"label":"进度","min-width":"150","max-width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{on:{"click":function($event){return _vm.schedule(scope.row.total, scope.row.existing)}}},[(scope.row.type == 0)?_c('el-progress',{attrs:{"percentage":scope.row.total !== 0
                ? Math.floor((scope.row.existing / scope.row.total) * 10000) /
                  100
                : 0}}):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"warning","plain":""},on:{"click":function () {
              _vm.form['id'] = scope.row.id;
              _vm.form['name'] = scope.row.name;
              _vm.form['type'] = scope.row.type;
              _vm.form['classId'] = scope.row.class_id;
              _vm.state = 'modify';
              _vm.addShow = true;
            }}},[_vm._v("编辑")]),_c('el-button',{attrs:{"size":"mini","type":"danger","plain":""},on:{"click":function($event){return _vm.remove([scope.row.id])}}},[_vm._v("删除")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.where.page,"page-sizes":_vm.$constant.web.pageSizes,"page-size":_vm.where.limit,"pager-count":5,"layout":"total, sizes, prev, pager, next","total":_vm.where.total},on:{"size-change":function (size) {
        _vm.where.limit = size;
        _vm.getList();
      },"current-change":function (page) {
        _vm.where.page = page;
        _vm.getList();
      }}}),_c('el-dialog',{attrs:{"title":"API文档","visible":_vm.documentShow},on:{"update:visible":function($event){_vm.documentShow=$event}}},[_c('div',{staticStyle:{"height":"500px","overflow":"auto","padding":"10px"}},[_c('h2',[_vm._v("取号API")]),_c('p',[_vm._v("接口：*/api")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'token',
            value: 'API管理里面的API令牌'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("返回：{\"code\":1,\"msg\":5,\"data\":\"123\"}")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'code',
            value: '1为成功,0为失败'
          },
          {
            key: 'msg',
            value: '返回接口剩余数据数量'
          },
          {
            key: 'data',
            value: '数据'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("示例：")]),_c('div',{staticStyle:{"background-color":"#f1f3f1","border":"5px","padding":"5px 10px","box-sizing":"border-box"}},[_c('p',[_vm._v("*/api?token=c141dc7ad04b72095c75dedcfc61b2187b893596")]),_c('p',[_vm._v(" 获取API令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596内的数据，每访问一次返回一条数据。 ")])]),_c('h2',[_vm._v("上传API")]),_c('p',[_vm._v("接口：*/api")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'token',
            value: 'API管理里面的API令牌'
          },
          {
            key: 'value',
            value: '上传的数据'
          },
          {
            key: 'remarks',
            value: '上传的备注'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("返回：{\"code\":1}")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'code',
            value: '1为成功,0为失败'
          },
          {
            key: 'msg',
            value: '文字提示'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("示例：")]),_c('div',{staticStyle:{"background-color":"#f1f3f1","border":"5px","padding":"5px 10px","box-sizing":"border-box"}},[_c('p',[_vm._v(" */api?token=c141dc7ad04b72095c75dedcfc61b2187b893596&value=13800138000|666666&remarks=xx账号 ")]),_c('p',[_vm._v(" 上传API令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596 数据为：13800138000|666666 备注为：xx账号 的数据 ")])]),_c('h2',[_vm._v("查询API")]),_c('p',[_vm._v("接口：*/api")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'token',
            value: 'API管理里面的API令牌'
          },
          {
            key: 'select_data',
            value: '查询的数据'
          },
          {
            key: 'mode',
            value: '1为模糊匹配,2为完全匹配,0为查询所有'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("返回：{\"code\":1,\"msg\":\"select_ture\"}")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'code',
            value: '1为查询到结果,0为未查询到结果'
          },
          {
            key: 'msg',
            value: '返回查询状态'
          },
          {
            key: 'data',
            value: '返回查询数据'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("示例：")]),_c('div',{staticStyle:{"background-color":"#f1f3f1","border":"5px","padding":"5px 10px","box-sizing":"border-box"}},[_c('p',[_vm._v(" */api?token=c141dc7ad04b72095c75dedcfc61b2187b893596&select_data=查询数据&mode=1 ")]),_c('p',[_vm._v(" 查询API令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596 内的数据。 ")])]),_c('h2',[_vm._v("云参数API")]),_c('p',[_vm._v("接口：*/api")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'token',
            value: '云参数令牌'
          },
          {
            key: 'type',
            value: 'pram'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("返回：{\"code\":1,\"msg\":\"6\",\"data\":\"1\"}")]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'code',
            value: '1为成功,0为失败'
          },
          {
            key: 'msg',
            value: '接口访问次数'
          },
          {
            key: 'data',
            value: '参数值'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"value"}})],1),_c('p',[_vm._v("示例：")]),_c('div',{staticStyle:{"background-color":"#f1f3f1","border":"5px","padding":"5px 10px","box-sizing":"border-box"}},[_c('p',[_vm._v(" */api?token=c141dc7ad04b72095c75dedcfc61b2187b893596&type=pram ")]),_c('p',[_vm._v(" 查询云参数令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596的参数值。 ")])]),_c('p',[_vm._v("注意事项：")]),_c('div',{staticStyle:{"background-color":"#f1f3f1","border":"5px","padding":"5px 10px","box-sizing":"border-box","margin-bottom":"14px"}},[_c('p',[_vm._v("参数内不可包含 \" ( ) < > \\ 字符")])]),_c('el-table',{attrs:{"border":"","size":"mini","data":[
          {
            key: 'code',
            value: 1,
            explain: '成功'
          },
          {
            key: 'code',
            value: 0,
            explain: '失败'
          },
          {
            key: 'msg',
            value: 'token_err',
            explain: 'token错误'
          },
          {
            key: 'msg',
            value: 'token_null',
            explain: 'token参数为空'
          },
          {
            key: 'msg',
            value: 'data_not_enough',
            explain: 'api取号数量已用完'
          },
          {
            key: 'msg',
            value: 'data_err',
            explain: '取号失败'
          },
          {
            key: 'msg',
            value: 'data_null',
            explain: '可获取数据为0'
          },
          {
            key: 'msg',
            value: 'select_ture',
            explain: '查询到结果'
          },
          {
            key: 'msg',
            value: 'select_not',
            explain: '未查询到结果'
          }
        ]}},[_c('el-table-column',{attrs:{"label":"参数","prop":"key"}}),_c('el-table-column',{attrs:{"label":"值","prop":"value"}}),_c('el-table-column',{attrs:{"label":"说明","prop":"explain"}})],1)],1)]),_c('el-dialog',{attrs:{"title":"表单","visible":_vm.addShow,"width":"350px"},on:{"update:visible":function($event){_vm.addShow=$event}}},[_c('el-form',{attrs:{"size":"mini","label-width":"auto"}},[_c('el-form-item',{attrs:{"label":"API名称"}},[_c('el-input',{attrs:{"size":"mini","placeholder":"请输入API名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"接口类型"}},[_c('el-select',{attrs:{"size":"mini"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('el-option',{attrs:{"label":"请选择","value":null}}),_c('el-option',{attrs:{"label":"获取","value":0}}),_c('el-option',{attrs:{"label":"上传","value":1}})],1)],1),_c('el-form-item',{attrs:{"label":"选择分类"}},[_c('el-select',{attrs:{"size":"mini"},model:{value:(_vm.form.classId),callback:function ($$v) {_vm.$set(_vm.form, "classId", $$v)},expression:"form.classId"}},[_c('el-option',{attrs:{"label":"请选择","value":null}}),_vm._l((_vm.classList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.class_name,"value":item.id}})})],2)],1),_c('el-form-item',[(_vm.state === 'add')?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.add}},[_vm._v("提交")]):_vm._e(),(_vm.state === 'modify')?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.modify}},[_vm._v("提交")]):_vm._e(),_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){_vm.addShow = false}}},[_vm._v("取消")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }