<template>
  <div id="ApiManagement">
    <el-form size="mini" :inline="true">
      <el-form-item>
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="
              () => {
                state = 'add';
                addShow = true;
              }
            "
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-delete"
            @click="
              remove($constant.method.getArrayValueByKey(selectionTableData))
            "
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="$router.go(0)"
          ></el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入名称"
          size="mini"
          v-model="where.name"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select size="mini" v-model="where.type">
          <el-option label="请选择类型" :value="null"></el-option>
          <el-option label="获取" :value="1"></el-option>
          <el-option label="上传" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          plain
          icon="el-icon-search"
          @click="getList"
          >搜索</el-button
        >
        <el-button
          size="mini"
          type="success"
          plain
          icon="el-icon-document"
          @click="documentShow = true"
          >API文档</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      size="mini"
      border
      :data="tableData"
      @selection-change="
        selection => {
          selectionTableData = selection;
        }
      "
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="ID" type="index" width="50"></el-table-column>
      <el-table-column label="API名称" prop="name"></el-table-column>
      <el-table-column label="API类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type">上传</span>
          <span v-else>取号</span>
        </template>
      </el-table-column>
      <el-table-column
        label="API令牌"
        prop="token"
        width="260"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        prop="add_time"
        width="140"
      ></el-table-column>
      <el-table-column label="进度" min-width="150" max-width="350">
        <template slot-scope="scope">
          <div @click="schedule(scope.row.total, scope.row.existing)">
            <el-progress
              v-if="scope.row.type == 0"
              :percentage="
                scope.row.total !== 0
                  ? Math.floor((scope.row.existing / scope.row.total) * 10000) /
                    100
                  : 0
              "
            ></el-progress>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="warning"
            plain
            @click="
              () => {
                form['id'] = scope.row.id;
                form['name'] = scope.row.name;
                form['type'] = scope.row.type;
                form['classId'] = scope.row.class_id;
                state = 'modify';
                addShow = true;
              }
            "
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click="remove([scope.row.id])"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="
        size => {
          where.limit = size;
          getList();
        }
      "
      @current-change="
        page => {
          where.page = page;
          getList();
        }
      "
      :current-page="where.page"
      :page-sizes="$constant.web.pageSizes"
      :page-size="where.limit"
      :pager-count="5"
      layout="total, sizes, prev, pager, next"
      :total="where.total"
    >
    </el-pagination>

    <el-dialog title="API文档" :visible.sync="documentShow">
      <div style="height: 500px; overflow: auto; padding: 10px;">
        <h2>取号API</h2>
        <p>接口：*/api</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'token',
              value: 'API管理里面的API令牌'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>返回：{"code":1,"msg":5,"data":"123"}</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'code',
              value: '1为成功,0为失败'
            },
            {
              key: 'msg',
              value: '返回接口剩余数据数量'
            },
            {
              key: 'data',
              value: '数据'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>示例：</p>
        <div
          style="background-color: #f1f3f1; border: 5px; padding: 5px 10px; box-sizing: border-box"
        >
          <p>*/api?token=c141dc7ad04b72095c75dedcfc61b2187b893596</p>
          <p>
            获取API令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596内的数据，每访问一次返回一条数据。
          </p>
        </div>
        <h2>上传API</h2>
        <p>接口：*/api</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'token',
              value: 'API管理里面的API令牌'
            },
            {
              key: 'value',
              value: '上传的数据'
            },
            {
              key: 'remarks',
              value: '上传的备注'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>返回：{"code":1}</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'code',
              value: '1为成功,0为失败'
            },
            {
              key: 'msg',
              value: '文字提示'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>示例：</p>
        <div
          style="background-color: #f1f3f1; border: 5px; padding: 5px 10px; box-sizing: border-box"
        >
          <p>
            */api?token=c141dc7ad04b72095c75dedcfc61b2187b893596&value=13800138000|666666&remarks=xx账号
          </p>
          <p>
            上传API令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596
            数据为：13800138000|666666 备注为：xx账号 的数据
          </p>
        </div>
        <h2>查询API</h2>
        <p>接口：*/api</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'token',
              value: 'API管理里面的API令牌'
            },
            {
              key: 'select_data',
              value: '查询的数据'
            },
            {
              key: 'mode',
              value: '1为模糊匹配,2为完全匹配,0为查询所有'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>返回：{"code":1,"msg":"select_ture"}</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'code',
              value: '1为查询到结果,0为未查询到结果'
            },
            {
              key: 'msg',
              value: '返回查询状态'
            },
            {
              key: 'data',
              value: '返回查询数据'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>示例：</p>
        <div
          style="background-color: #f1f3f1; border: 5px; padding: 5px 10px; box-sizing: border-box"
        >
          <p>
            */api?token=c141dc7ad04b72095c75dedcfc61b2187b893596&select_data=查询数据&mode=1
          </p>
          <p>
            查询API令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596 内的数据。
          </p>
        </div>
        <h2>云参数API</h2>
        <p>接口：*/api</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'token',
              value: '云参数令牌'
            },
            {
              key: 'type',
              value: 'pram'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>返回：{"code":1,"msg":"6","data":"1"}</p>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'code',
              value: '1为成功,0为失败'
            },
            {
              key: 'msg',
              value: '接口访问次数'
            },
            {
              key: 'data',
              value: '参数值'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="说明" prop="value"></el-table-column>
        </el-table>
        <p>示例：</p>
        <div
          style="background-color: #f1f3f1; border: 5px; padding: 5px 10px; box-sizing: border-box"
        >
          <p>
            */api?token=c141dc7ad04b72095c75dedcfc61b2187b893596&type=pram
          </p>
          <p>
            查询云参数令牌为：c141dc7ad04b72095c75dedcfc61b2187b893596的参数值。
          </p>
        </div>
        <p>注意事项：</p>
        <div
          style="background-color: #f1f3f1; border: 5px; padding: 5px 10px; box-sizing: border-box; margin-bottom: 14px;"
        >
          <p>参数内不可包含 " ( ) &lt; &gt; \ 字符</p>
        </div>
        <el-table
          border
          size="mini"
          :data="[
            {
              key: 'code',
              value: 1,
              explain: '成功'
            },
            {
              key: 'code',
              value: 0,
              explain: '失败'
            },
            {
              key: 'msg',
              value: 'token_err',
              explain: 'token错误'
            },
            {
              key: 'msg',
              value: 'token_null',
              explain: 'token参数为空'
            },
            {
              key: 'msg',
              value: 'data_not_enough',
              explain: 'api取号数量已用完'
            },
            {
              key: 'msg',
              value: 'data_err',
              explain: '取号失败'
            },
            {
              key: 'msg',
              value: 'data_null',
              explain: '可获取数据为0'
            },
            {
              key: 'msg',
              value: 'select_ture',
              explain: '查询到结果'
            },
            {
              key: 'msg',
              value: 'select_not',
              explain: '未查询到结果'
            }
          ]"
        >
          <el-table-column label="参数" prop="key"></el-table-column>
          <el-table-column label="值" prop="value"></el-table-column>
          <el-table-column label="说明" prop="explain"></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog title="表单" :visible.sync="addShow" width="350px">
      <el-form size="mini" label-width="auto">
        <el-form-item label="API名称">
          <el-input
            size="mini"
            placeholder="请输入API名称"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="接口类型">
          <el-select size="mini" v-model="form.type">
            <el-option label="请选择" :value="null"></el-option>
            <el-option label="获取" :value="0"></el-option>
            <el-option label="上传" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择分类">
          <el-select size="mini" v-model="form.classId">
            <el-option label="请选择" :value="null"></el-option>
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            plain
            @click="add"
            v-if="state === 'add'"
            >提交</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            @click="modify"
            v-if="state === 'modify'"
            >提交</el-button
          >
          <el-button size="mini" @click="addShow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ApiManagement",
  data() {
    return {
      state: "",
      documentShow: false,
      addShow: false,
      form: {
        id: "",
        name: "",
        type: "",
        classId: ""
      },
      where: {
        id: this.$constant.method.getUserInfo()["id"],
        type: "",
        name: "",
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      classList: [],
      selectionTableData: []
    };
  },
  mounted() {
    this.$http({
      url: "/data_class/list",
      method: "POST",
      data: {
        id: this.$constant.method.getUserInfo()["id"],
        page: 1,
        limit: 9999999
      }
    }).then(response => {
      this.classList = response.list;
    });
    this.getList();
  },
  methods: {
    // 增加
    add() {
      if (
        !this.form.name ||
        !this.form.classId ||
        this.form.type === null ||
        this.form.type === ""
      ) {
        this.$message.error("请完善表单");
        return false;
      }
      this.$http({
        url: "/api_management/add",
        method: "POST",
        data: this.form
      }).then(() => {
        this.$message({
          message: "添加成功",
          type: "success"
        });
        this.addShow = false;
        this.form = {
          name: "",
          type: "",
          classId: ""
        };
        this.getList();
      });
    },

    // 删除
    remove(ids) {
      this.$confirm("确定删除").then(() => {
        this.$http({
          url: "/api_management/remove",
          method: "POST",
          data: {
            ids: ids
          }
        }).then(() => {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getList();
        });
      });
    },

    // 修改管理员
    modify() {
      this.$http({
        url: "/api_management/modify",
        method: "POST",
        data: this.form
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success"
        });
        this.addShow = false;
        this.getList();
      });
    },

    // 得到数据列表
    getList() {
      this.$http({
        url: "/api_management/list",
        method: "POST",
        data: this.where
      }).then(response => {
        this.where.total = response.count;
        this.tableData = response.list;
      });
    },

    // 进度明细
    schedule(total, surplus) {
      this.$confirm(
        "数据总数：" +
          total +
          " 已使用：" +
          surplus +
          " 剩余：" +
          (total - surplus),
        "进度明细",
        {
          showCancelButton: false
        }
      );
    }
  }
};
</script>

<style scoped></style>
